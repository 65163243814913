import React from "react"
import Input from "./items/Input"
import Textarea from "./items/Textarea"
import Button from "../common/Button"
import Select from "./items/Select"
import Checkbox from "./items/Checkbox"

function FotoreisenAnfragen({ reise, dates, url }) {
  let datesArr = []

  if (dates) {
    dates.forEach(date => datesArr.push(date.value))
  }

  if (datesArr.length === 0) {
    return <>Aktuell sind keine Termine geplant.</>
  }

  return (
    <form
      name="Fotoreisen und Fotoexkursionen"
      method="POST"
      data-netlify="true"
      action="/anfrage-gesendet/"
      className="space-y-4"
    >
      <input
        type="hidden"
        name="form-name"
        value="Fotoreisen und Fotoexkursionen"
      />
      <input type="hidden" name="Fotoreisen / Fotoexkursionen" value={reise} />
      <input type="hidden" name="Link" value={url} />
      <Select
        label="Datum"
        id="date"
        name="Datum"
        options={datesArr}
        required
      />
      <div className="grid-cols-2 gap-4 sm:grid ">
        <Input
          type="text"
          label="Vorname"
          id="vorname"
          name="Vorname"
          placeholder="Max"
          required
        />
        <Input
          type="text"
          label="Nachname"
          id="nachname"
          name="Nachname"
          placeholder="Mustermann"
          required
        />
      </div>
      <Input
        type="tel"
        label="Telefon"
        id="telefon"
        name="Telefon"
        autoComplete="tel"
        placeholder="01234 567890"
        inputMode="numeric"
        required
      />
      <Input
        type="email"
        label="E-Mail"
        id="email"
        name="E-Mail"
        placeholder="max@muster.de"
        autoComplete="email"
        required
      />
      <Textarea label="Kommentar" id="kommentar" name="Kommentar" required />
      <div className="space-y-5">
        <Checkbox
          label="Datenschutzerklärung"
          id="datenschutz-einwilligung"
          name="Datenschutzerklärung"
          text="Es gilt der Datenschutz des Naturblick Verlags. Ihre persönlichen Daten benötigen wir zur Einrichtung und Verwaltung Ihres Abonnements. Ich stimme zu, dass meine Angaben aus dem Bestellformular zur Bearbeitung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an verlag@naturblick.com widerrufen."
          required
        />
        <Checkbox
          label="E-Mail-Marketing"
          id="e-mail-marketing-einwilligung"
          name="E-Mail-Marketing"
          text="Ich willige ein, dass mich Naturblick Verlag per E-Mail über die von ihm angebotenen Zeitschriften, Online-Angebote, Printprodukte, Veranstaltungen informiert. Meine Daten werden ausschließlich zu diesem Zweck genutzt. Eine Weitergabe an Dritte erfolgt nicht. Ich kann die Einwilligung jederzeit per E-Mail an info@naturblick.com widerrufen"
        />
      </div>
      <Button submit text="Anfrage senden" className="w-full" />
    </form>
  )
}

export default FotoreisenAnfragen
