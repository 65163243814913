import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/layouts/Default"
import { H1 } from "../../components/typography/Headings"
import P from "../../components/typography/P"
import Button from "../../components/common/Button"
import Container from "../../components/common/Container"
import GridTwo from "../../components/common/GridTwo"
import {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
  CheckIcon,
  CurrencyEuroIcon,
  FlagIcon,
  XIcon,
} from "@heroicons/react/outline"
import "react-image-gallery/styles/css/image-gallery.css"
import ReactHtmlParser from "react-html-parser"
import FotoreisenAnfragen from "../../components/forms/FotoreisenAnfragen"
import Breadcrumb from "../../components/common/Breadcrumb"
import Gallery from "../../components/common/Gallery"
import CtaSection from "../../components/sections/CtaSection"

export default function Page({ data, location }) {
  const {
    anreise,
    attendees,
    dates,
    description,
    excerpt,
    highlights,
    title,
    title_slug,
    price,
    place,
    leistungen,
    notIncluded,
    info,
    titleImage,
    type,
    galleryThumbnails,
    galleryImages,
    clothes,
    gear,
  } = data.fotoreisen

  return (
    <Layout location={location} title={`${title} | ${type}`} desc={excerpt}>
      <div className="relative">
        <div className="absolute inset-0 w-full h-full">
          <GatsbyImage
            image={getImage(titleImage)}
            alt="Titel"
            className="absolute inset-0 w-full h-full"
          />
        </div>
        <div className="relative z-10 items-center justify-center py-16 bg-black bg-opacity-50 md:py-32 fluid">
          <div className="p-6 mx-auto text-center text-white max-w-7xl">
            <span>{type}</span>

            <H1 display>{title}</H1>
          </div>
        </div>
      </div>
      <Breadcrumb
        pages={[
          {
            name: "Fotoreisen & Fotoexkursionen ",
            to: "/fotoreisen-fotoexkursionen/",
          },
          {
            name: title,
            to: `/fotoreisen-fotoexkursionen/${title_slug}`,
          },
        ]}
      />
      <Container noPadding>
        <CtaSection
          title="Sonderausgabe 2025"
          text="Mit NATURBLICK der Natur auf der Spur! Entdecken Sie unsere neue kostenloste Sonderausgabe direkt zum Runterladen."
          link="/verlag/sonderausgaben/sonderausgabe-2025/"
          linkText="Zur Sonderausgabe"
          image={data.sonderausgabe}
          location={location}
        />
        <GridTwo className="my-8">
          <div>
            <div className="text-xs text-gray-700">Einleitung:</div>
            <hr className="my-3 mb-5 border-t border-gray-300" />
            <P>{excerpt}</P>
            <Button
              anchor={`/fotoreisen-fotoexkursionen/${title_slug}/#anfragen`}
              text={`${type} anfragen`}
              className="w-full mt-5"
            />
          </div>
          <div>
            <div className="text-xs text-gray-700">Details:</div>
            <hr className="my-3 mb-5 border-t border-gray-300" />
            <div className="space-y-10">
              {price && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center p-2 text-white rounded bg-brand">
                    <CurrencyEuroIcon className="w-8 h-8" />
                  </div>
                  <div className="flex-1 ml-3">
                    <div className="text-xs text-gray-700">Preis:</div>
                    {price}
                  </div>
                </div>
              )}
              {place && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center p-2 text-white rounded bg-brand">
                    <LocationMarkerIcon className="w-8 h-8" />
                  </div>
                  <div className="flex-1 ml-3">
                    <div className="text-xs text-gray-700">Reiseziel:</div>
                    {place}
                  </div>
                </div>
              )}
              {attendees && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center p-2 text-white rounded bg-brand">
                    <UsersIcon className="w-8 h-8" />
                  </div>
                  <div className="flex-1 ml-3">
                    <div className="text-xs text-gray-700">Teilnehmerzahl:</div>
                    {attendees}
                  </div>
                </div>
              )}
              {dates && Array.isArray(dates) && dates.length > 0 && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center p-2 text-white rounded bg-brand">
                    <CalendarIcon className="w-8 h-8" />
                  </div>
                  <div className="flex-1 ml-3">
                    <div className="text-xs text-gray-700">Reisedatum:</div>
                    {dates.map((date, i) => {
                      return <div key={i}>{date.value}</div>
                    })}
                  </div>
                </div>
              )}
              {anreise && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center p-2 text-white rounded bg-brand">
                    <FlagIcon className="w-8 h-8" />
                  </div>
                  <div className="flex-1 ml-3">
                    <div className="text-xs text-gray-700">Anreise:</div>
                    {anreise}
                  </div>
                </div>
              )}
              {Array.isArray(info) && info.length > 0 && (
                <div>
                  <div>
                    <div className="text-xs text-gray-700">Zusatzinfos:</div>
                    <hr className="my-3 border-t border-gray-300" />
                  </div>
                  <ul className="space-y-3 text-sm">
                    {info.map((entry, i) => {
                      return (
                        <li className="" key={i}>
                          {entry.value}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </GridTwo>
        {Array.isArray(leistungen) && leistungen.length > 0 && (
          <div className="mb-16">
            <div className="text-xs text-gray-700">Leistungen:</div>
            <hr className="my-3 mb-5 border-t border-gray-300" />
            <ul className="grid gap-8 md:grid-cols-2">
              {leistungen.map((leistung, i) => {
                return (
                  <li key={i} className="flex flex-row items-stretch">
                    <div className="flex items-start text-green-500">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                    <div className="flex-1 ml-3">{leistung.value}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {Array.isArray(notIncluded) && notIncluded.length > 0 && (
          <div className="mb-16">
            <div className="text-xs text-gray-700">Nicht enthalten:</div>
            <hr className="my-3 mb-5 border-t border-gray-300" />
            <ul className="grid gap-8 md:grid-cols-2">
              {notIncluded.map((entry, i) => {
                return (
                  <li key={i} className="flex flex-row items-stretch">
                    <div className="flex items-start text-red-500">
                      <XIcon className="w-6 h-6" />
                    </div>
                    <div className="flex-1 ml-3">{entry.value}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {Array.isArray(highlights) && highlights.length > 0 && (
          <div className="mb-16">
            <div className="text-xs text-gray-700">Highlights:</div>
            <hr className="my-3 mb-5 border-t border-gray-300" />

            <ul className="grid gap-8 md:grid-cols-2">
              {highlights.map((highlight, i) => {
                return (
                  <li key={i} className="flex flex-row items-stretch">
                    <div className="flex items-start text-green-500">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                    <div className="flex-1 ml-3">{highlight.value}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {Array.isArray(gear) && gear.length > 0 && (
          <div className="mb-16">
            <div className="text-xs text-gray-700">
              Tipps für die Fotoausrüstung:
            </div>
            <hr className="my-3 mb-5 border-t border-gray-300" />

            <ul className="grid gap-8 md:grid-cols-2">
              {gear.map((gea, i) => {
                return (
                  <li key={i} className="flex flex-row items-stretch">
                    <div className="flex items-start text-green-500">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                    <div className="flex-1 ml-3">{gea.value}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {Array.isArray(clothes) && clothes.length > 0 && (
          <div className="mb-16">
            <div className="text-xs text-gray-700">Tipps für Bekleidung:</div>
            <hr className="my-3 mb-5 border-t border-gray-300" />

            <ul className="grid gap-8 md:grid-cols-2">
              {clothes.map((cloth, i) => {
                return (
                  <li key={i} className="flex flex-row items-stretch">
                    <div className="flex items-start text-green-500">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                    <div className="flex-1 ml-3">{cloth.value}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </Container>

      {galleryImages.images.length > 0 && (
        <Container>
          <div className="text-xs text-gray-700">Fotos:</div>
          <hr className="my-3 border-t border-gray-300" />
          <Gallery thumbnails={galleryThumbnails} images={galleryImages} />
        </Container>
      )}

      <Container>
        <GridTwo>
          {description && (
            <div>
              <div className="text-xs text-gray-700">Beschreibung:</div>
              <hr className="my-3 border-t border-gray-300" />
              <div className="prose">{ReactHtmlParser(description)}</div>
            </div>
          )}
          <div>
            <div className="text-xs text-gray-700">Anfragen:</div>
            <hr className="my-3 border-t border-gray-300" />
            <div id="anfragen">
              <FotoreisenAnfragen
                reise={title}
                dates={dates}
                url={`${location.href}`}
              />
            </div>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    fotoreisen(id: { eq: $id }) {
      anreise
      attendees
      prerequisite {
        value
      }
      notIncluded {
        value
      }
      dates {
        value
      }
      description
      excerpt
      highlights {
        value
      }
      clothes {
        value
      }
      gear {
        value
      }
      title_slug
      title
      published
      price
      place
      leistungen {
        value
      }
      info {
        value
      }
      type
      galleryThumbnails: fields {
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 390)
            }
          }
        }
      }
      galleryImages: fields {
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
      }
      titleImage {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { cropFocus: ATTENTION }
            width: 1600
          )
        }
      }
    }
    sonderausgabe: file(relativePath: { eq: "sonderausgabe_2025-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
